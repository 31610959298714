/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Make text selectable for copy paste */
body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  }

.progress {
  position: fixed;
  top: 50%;
  left: 50%;
  //transform: translate(-50%, -50%);
  transform: scale(6);
}

ion-select {
  --highlight-color-valid: var(--ion-color-primary);
  }

  /* ion-input */
.ion-valid.sc-ion-input-md-h {
  --highlight-color: var(--ion-color-primary);
}

.has-focus.sc-ion-input-md-h input.sc-ion-input-md {
  caret-color: var(--ion-color-primary);
}

/* ion-textarea */
.ion-valid.sc-ion-textarea-md-h {
  --highlight-color: var(--ion-color-primary);
}

.sc-ion-textarea-md-h {
  --highlight-color: var(--ion-color-primary);
}